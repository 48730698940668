var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("推进科研成果转化 培养高素质人才")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails13')}}},[_vm._v("上一条：2019互联网岳麓峰会4月1号启幕：“互联网+教育” 让教育教学更智能")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails15')}}},[_vm._v("下一条：打通高校科研与市场“隔断” 服务社会高质量发展")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2019-01-10 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news14/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news14/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news14/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news14/news4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 在2018国家科学技术奖励大会上，湖南的高校有20项成果获得国家科技大奖。湖南各高校积极推进科研成果转化，培养高素质科研以及专业技术型人才，以高质量教育支撑高质量发展。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 基于张尧学院士团队的透明计算理论成果，湖南新云网可以确保在服务器短暂宕机或临时断网的情况下，终端可以继续运行、不丢失网络数据，电脑感染病毒后，仅重启就可一键恢复。近三年来，中南大学有五项科技成果的转让费超过亿元，开园仅七个月的中南大学科技园也为学校科技成果转化搭建服务平台。目前科技园已有123家科技型企业注册，15家企业入驻。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 中南大学科技园发展有限公司副总经理 伍晓赞：我们园区的项目已经有五个院士的科技成果在这里转化，我们平台可以有效地搭建学校和企业的桥梁，为高端的科技成果和人才找出口。 ")])])
}]

export { render, staticRenderFns }